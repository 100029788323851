*{
  box-sizing: border-box;
}

html {
  height: 100%;
  /* width: 100vw; */
}

body {
  font-family: BlinkMacSystemFont,-apple-system,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",Helvetica,Arial,sans-serif;
  background-size: cover;
  margin: 0;
  background: linear-gradient(141deg,#fa0a62 0,#f14668 71%,#f7595f 100%) no-repeat center center fixed;
}

main {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  /* display: none; */
}

h1,h2,h3,h4,h5,h6,p {
  color: #fff;
}

h2 {
  font-size: 20pt;
  margin: 6pt 0;
}

h3 {
  font-size: 18pt;
  margin: 6pt 0;
}

#submit {
  margin-top: 20pt;
  font-family: 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
  font-size: 20pt;
  padding: 8pt;
  border-radius: 4pt;
  background-image: linear-gradient(141deg,#27BA98 0,#23a971 100%);
  border: none;
  font-weight: 800;
  color: #fff;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.24);
  cursor: pointer;
  transition: filter 0.2s ease-in-out;
  text-decoration: none !important;
}

a {
  color: white;
  text-decoration-thickness: 1px;
}

button:hover, button:focus {
  filter: brightness(90%);
}

#banner {
  margin-top: 3em;
  text-align: center;
  margin-bottom: 5vh;
}

#submissionsGuide {
  margin: auto;
  padding: 5px;
  text-align: center;
  margin-bottom: 5vh;
  background-color: #5f0057;
  width: 50%;
  border-radius: 25px;

}


#screenSelect {
  position: absolute;
  top: 0.75em;
  left: 1em;
  font-size: 1.5vw;
  border: 1px solid white;
  color: white;
  border-radius: 8px;
  border-color: white;
  height: 2em;
  font-family: 'Open Sans', Roboto, 'Helvetica Neue', sans-serif;
  cursor: pointer;
  display: flex;
  flex-flow: row nowrap;
  padding: 0;
  border-collapse: collapse;
  overflow: hidden;
}

#screenSelect li {
  font-size: inherit;
  list-style-type: none;
  padding: 0;
  text-decoration: none;
  cursor: pointer;
  color: white;
  background-color: #fa0a62;
  display: flex;
  align-items: center;
  justify-content: center;
}

#screenSelect li a:hover, #screenSelect li a:focus {
  background-color: #f14668;
}

#screenSelect li a {
  font-size: inherit;
  height: 100%;
  padding: 0.2em 0.4em;
  text-decoration: none;
  color: white;
  font-weight: 800;
  display: flex;
  align-items: center;
  justify-content: center;
}

#edit {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 20pt;
  border-radius: 0.3em;
  text-align: center;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  width: 1.4em;
  height: 1.4em;
  vertical-align: baseline;
  text-decoration: none;
  color: white;
}

#edit:hover, #edit:focus {
  background-color: rgba(255, 255, 255, 0.4);
}

#edit i {
  vertical-align: baseline;
  display: flex;
  text-align: center;
  justify-content: center;
  font-weight: 500;
}

#edit i::before {
  margin: 0;
  vertical-align: middle;
}

h1 {
  font-size: 2.5em;
  font-weight: 700;
  margin: 0 auto;
  text-align: center;
  font-style: italic;
}

#countdown-content {
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 5vh;
}

#countdown-content * {
  margin: 0;
}

#countdown-content h2 {
  font-size: 80pt;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.4);
}

#countdown-event {
  font-size: 20pt;
  height: 10vh;
  font-weight: 700;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: space-between;
}

#events {
  position: absolute;
  bottom: 8vh;
  display: flex;
  flex-flow: row wrap;
  width: 90%;
  justify-content: center;
  align-items: center;
}

.sep {
  flex-grow: 1;
}

#events div {
  width: 25%;
}

section h2 {
  margin: 0.5em auto;
  text-align: center;
}

table {
  width: 100%;
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0;
  color: white;
  font-weight: 600;
  font-size: 16pt;
  table-layout: fixed;
}

.scrollableTable {
  overflow: auto;
  max-height: 70vh;
  width: 80%;
  margin: 0 auto;
  border-radius: 5px;
  border: 1px solid white;
}

.scrollableTable thead th { 
  position: sticky; top: 0; z-index: 1;
  border-bottom: 1.5px solid white;
}

table thead th {
  background-color: #e8085a;
}

tr button{
  transition: background-color 0.2s ease-in-out;
}

tr:hover {
  background-color: #ffffff22;
}

tr {
  width: 100%;
}

thead,tbody{
  width: 100%;
  overflow-y: auto;
}

.highlighted, a.active, a.active:active, a.active:focus {
  background-color: #ac124aa0 !important;
  color: white !important;
  font-weight: 800 !important;
}

.highlighted:hover, a.active:hover {
  background-color: #ac124a !important;
}

th, td {
  padding: 0.35em;
}

#timetable th, #timetable td {
  width: 80%;
}

#teamtable th, #teamtable td {
  width: 70%;
}

tr *:first-child {
  border-right: 1px solid white;
}

#timetable tr *:first-child {
  width: 20%;
}

#teamtable tr *:first-child {
  width: 30%;
}

tr td {
  border-top: 1px solid white;
}

#awards-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 70vh;
  width: 90%;
  margin: 0 auto;
}

#awards-container div {
  min-width: 500px;
  flex-grow: 1;
  flex-basis:500px;
  height: 30vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

#awards-container div img {
  background-color: white;
  padding: 10px;
  aspect-ratio: 16/9;
  border-radius: 5px;
  height: 60%;
  object-position: 50% 50%;
  object-fit: contain;
  box-shadow: 5px 5px 10px rgb(0, 0, 0, 0.25);
  user-select: none;
}

/* Judge Login Styles */
.judge-login {
    max-width: 400px;
    margin: 2rem auto;
    padding: 2rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 8px;
    backdrop-filter: blur(10px);
}

.judge-login h2 {
    text-align: center;
    margin-bottom: 1.5rem;
}

.form-group {
    margin-bottom: 1rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: white;
}

.form-group input,
.form-group select,
.form-group textarea {
    width: 100%;
    padding: 0.5rem;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.1);
    color: white;
}

.form-group textarea {
    min-height: 100px;
    resize: vertical;
}

.error {
    color: #ff4444;
    text-align: center;
    margin-bottom: 1rem;
}

/* Judge Dashboard Styles */
.judge-dashboard {
    max-width: 800px;
    margin: 2rem auto;
    padding: 2rem;
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
}

.logout-btn {
    padding: 0.5rem 1rem;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    color: white;
    cursor: pointer;
}

.notes-section {
    background: rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.notes-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.note-card {
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 4px;
}

.note-card h4 {
    margin: 0 0 0.5rem 0;
}

.note-card small {
    display: block;
    margin-top: 0.5rem;
    color: rgba(255, 255, 255, 0.7);
}

button {
    background: linear-gradient(141deg,#27BA98 0,#23a971 100%);
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
}

button:hover {
    filter: brightness(110%);
}

/* Awards Section Styles */
.awards-section {
    background: rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.awards-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 1rem;
    margin-bottom: 1rem;
}

.award-item {
    background: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 4px;
}

.award-item h4 {
    margin: 0 0 0.5rem 0;
    color: white;
}

.publish-btn {
    width: 100%;
    margin-top: 1rem;
    padding: 0.75rem;
    font-size: 1.1em;
}

.publish-btn:disabled {
    background: rgba(255, 255, 255, 0.1);
    cursor: not-allowed;
}

/* Footer Styles */
.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    text-align: center;
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
}

.footer a {
    color: white;
    text-decoration: none;
    opacity: 0.8;
    transition: opacity 0.2s;
}

.footer a:hover {
    opacity: 1;
}

/* Rubric Evaluation Styles */
.rubric-section {
    background: rgba(255, 255, 255, 0.1);
    padding: 1.5rem;
    border-radius: 8px;
    margin-bottom: 2rem;
}

.team-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 1rem;
}

.team-evaluation-card {
    background: rgba(255, 255, 255, 0.05);
    padding: 1rem;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.team-info {
    flex: 1;
}

.team-info h4 {
    margin: 0 0 0.5rem 0;
}

.team-info p {
    margin: 0;
    font-size: 0.9em;
    opacity: 0.8;
}

.evaluation-score {
    color: #27BA98;
    font-weight: bold;
    margin-top: 0.5rem !important;
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background: linear-gradient(141deg,#fa0a62 0,#f14668 71%,#f7595f 100%);
    padding: 2rem;
    border-radius: 8px;
    max-width: 800px;
    width: 90%;
    max-height: 90vh;
    overflow-y: auto;
    position: relative;
}

.close-btn {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0;
    width: 2rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    color: white;
}

.close-btn:hover {
    background: rgba(255, 255, 255, 0.1);
}

.rubric-evaluation {
    color: white;
}

.rubric-section {
    margin-bottom: 2rem;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

.rubric-section h4 {
    margin: 0 0 1rem 0;
    color: white;
}

.total-score {
    text-align: center;
    margin: 1rem 0;
    padding: 1rem;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 4px;
}

.total-score h4 {
    margin: 0;
    color: #27BA98;
    font-size: 1.2em;
}

/* Rubric Description Styles */
.rubric-instructions {
    background: rgba(255, 255, 255, 0.1);
    padding: 1rem;
    border-radius: 4px;
    margin-bottom: 2rem;
}

.rubric-instructions ul {
    margin: 0.5rem 0;
    padding-left: 1.5rem;
}

.rubric-instructions li {
    color: white;
    margin: 0.25rem 0;
}

.section-description {
    color: rgba(255, 255, 255, 0.8);
    font-style: italic;
    margin-bottom: 1rem;
}

.score-select {
    position: relative;
}

.score-descriptions {
    display: none;
    position: absolute;
    left: 100%;
    top: 0;
    margin-left: 1rem;
    background: rgba(0, 0, 0, 0.9);
    padding: 1rem;
    border-radius: 4px;
    width: 300px;
    z-index: 10;
}

.score-select:hover .score-descriptions,
.score-select:focus-within .score-descriptions {
    display: block;
}

.score-description {
    color: white;
    margin: 0.5rem 0;
    font-size: 0.9em;
}

.score-description strong {
    color: #27BA98;
}

.deduction-guide {
    list-style: none;
    padding: 0;
    margin: 0.5rem 0 1rem;
}

.deduction-guide li {
    color: white;
    margin: 0.25rem 0;
    font-size: 0.9em;
}